.Header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  color: var(--white);
}

.Header.is-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.Header-RightButton {
  order: 2;
}

.Header-LeftButton,
.Header-RightButton {
  display: flex;
  width: 50px;
  height: 100%;
  color: var(--white);
  border: none;
  background: none;
  padding: 0;
  align-items: center;
  justify-content: center;
}

.Header-Middle {
  max-width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-grow: 1;
  align-items: center;
}

.Header-Middle > h1 {
  font-size: 36px;
  font-weight: 800;
}
