.RoundButton {
  width: 60px;
  height: 60px;

  display: block;
  border-radius: 50%;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.05);
  color: var(--white);
  cursor: pointer;
  box-shadow: 0 5px 10px RGBA(0, 0, 0, 0.125);

  transition-property: box-shadow;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  -webkit-tap-highlight-color: transparent;
}

.RoundButton:focus {
  outline: none;
}

.RoundButton ::selection {
  background: transparent;
}

.RoundButton--sizeSmall {
  width: 40px;
  height: 40px;
}

.RoundButton-child {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

@media (hover: hover) and (pointer: fine) {
  .RoundButton:hover .RoundButton-child {
    transform: scale(1.2);
  }

  .RoundButton:hover {
    box-shadow: 0 3px 7px RGBA(0, 0, 0, 0.25);
  }
}

.RoundButton:active .RoundButton-child {
  transform: scale(1.2);
}


.RoundButton--colorRed {
  background: var(--red);
}

.RoundButton--colorGreen {
  background: var(--green);
}

.RoundButton--colorBlue {
  background: var(--blue);
}

.RoundButton--colorMagenta {
  background: var(--magenta);
}

.RoundButton--colorBlack {
  background: var(--black);
}

.RoundButton--colorWhite {
  background: var(--white);
  color: var(--black);
}

.RoundButton--styleGhost:hover,
.RoundButton--styleGhost {
  background: transparent;
  border: none;
  box-shadow: none;
}

.RoundButton.is-disabled {
  opacity: 0.5;
}