.LastRoundWidget {
  max-width: 450px;
  min-width: 280px;
  width: calc(100vw - 40px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  align-self: center;
}

.LastRoundWidget--stats {
  color: var(--white);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.LastRoundWidget--round {
  color: var(--black);
  background: var(--white);
}

.LastRoundWidget--extraSpaceForButton {
  padding-top: 35px;
  padding-bottom: 35px;
  margin-bottom: 20px;
}

.LastRoundWidget h2 {
  font-size: 12px;
}

.LastRoundWidget-topLine {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}

.LastRoundWidget-for {
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  line-height: 30px;
  font-size: 12px;
  text-align: center;
  opacity: 0.7;
  padding-top: 10px;
  height: 30px;
}

.LastRoundWidget-fab {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}
