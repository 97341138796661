:root {
  --blue: #31aff6;
  --green: #31f68c;
  --magenta: #9431f6;
  --red: #f63155;

  --black: #000;
  --white: #fff;

  --gold: #ffc36a;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2 {
  font: inherit;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  min-width: 100vw;
  min-height: 100vh;
}

::selection {
  background: none;
  color: inherit;
}

div {
  box-sizing: border-box;
}
