.SettingsLayout {
  background: var(--red);
  color: var(--white);
  min-height: 100vh;
  padding-top: 60px;
  padding-bottom: 10px;
}

.SettingsLayout-block {
  margin: 10px auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.SettingsLayout-label {
  margin: 0 auto 10px auto;
  text-align: center;
  display: block;
}

.SettingsLayout-tip {
  opacity: 0.7;
}

.DictsSelector {
  margin: 0 auto;
}

.DictsSelector-wrapper{
  max-width: 450px;
  min-width: 280px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin: 0 20px;
  padding: 20px;
  border-radius: 10px;
}

.DictsSelector-preloader {
  padding: 10px;
}
.DictsSelector-preloader-text {
  margin-top: 10px;
  font-size: 14px;
}

.DictsSelector-dict + .DictsSelector-dict {
  margin-top: 10px;
}
