.SplashLayout {
  width: 100%;
  height: 100vh;
}

.SplashLayout-Decoration {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background: var(--magenta);
  --squareSize: 100vh;
  --margin-rect: calc(var(--squareSize) / 100 * 11);
}

.SplashLayout-Decoration .rect1 {
  background: var(--green);
  width: var(--squareSize);
  height: var(--squareSize);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.SplashLayout-Decoration .rect2,
.SplashLayout-Decoration .rect3,
.SplashLayout-Decoration .rect4 {
  top: var(--margin-rect);
  bottom: var(--margin-rect);
  left: var(--margin-rect);
  right: var(--margin-rect);
  position: absolute;
}

.SplashLayout-Decoration .rect2 {
  background: var(--black);
}

.SplashLayout-Decoration .rect3 {
  background: var(--blue);
}

.SplashLayout-Decoration .rect4 {
  background: var(--red);
}

.SplashLayout-appTitle {
  position: absolute;
  color: var(--white);
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.SplashLayout-appTitle-description {
  font-weight: 500;
  line-height: 15px;
  font-size: 11px;
  opacity: 0.5;
  display: inline-block;
  padding: 0 25px;
  margin-bottom: 5px;
}

.SplashLayout-appTitle-word {
  font-weight: 800;
  line-height: 55px;
  font-size: 64px;
  text-align: center;
  letter-spacing: -0.03em;
  margin-top: 15px;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
