.Card,
.CardBack {
  max-width: 350px;
  min-width: 280px;
  width: 100%;
  border-radius: 20px;
  background: var(--white);
  padding: 10px;

  color: var(--black);
  position: absolute;

  height: 90vh;
  min-height: 280px;
  max-height: 650px;
  top: 5vh;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0 0 10px RGBA(0, 0, 0, 0.1);
  transform-origin: 50% 0%;
}

.CardBack {
  background-color: var(--white);
  color: var(--black);
}

.CardBack-inner {
  padding: 2px;
  border-radius: 10px;
  border: 1px solid RGBA(49, 175, 246, 0.5);
  height: 100%;
  transition: opacity 0.5s ease;
  opacity: 1;
}

.CardBack-pattern {
  padding: 2px;
  border-radius: 8px;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2331aff6' fill-opacity='0.5' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.CardBack-title {
  font-size: 48px;
  line-height: 48px;
  font-weight: 600;
  color: var(--blue);
  text-shadow: 0 0 6px var(--white);
  margin-bottom: 5px;
}

.CardBack-subTitle {
  font-size: 12px;
  opacity: 0.7;
  text-shadow: 0 0 3px var(--white);
}

.Card_inner {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid RGBA(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
}

.Card_number {
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  line-height: 15px;
  font-size: 12px;
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
}

.Card_title,
.Cards_tabooWords {
  text-align: center;
}

.Card_title_category {
  font-weight: 500;
  line-height: 15px;
  font-size: 11px;
  opacity: 0.5;
  text-transform: uppercase;
  display: inline-block;
  padding: 0 25px 5px;
  border-bottom: 1px solid RGBA(0, 0, 0, 0.1);
  margin-bottom: 5px;
}

.Card_title_word {
  font-weight: 800;
  line-height: 40px;
  font-size: 36px;
  text-align: center;
  letter-spacing: -0.03em;
}

.Cards_tabooWords_title {
  font-weight: 500;
  line-height: 15px;
  font-size: 11px;
  opacity: 0.5;
}

.Cards_tabooWords_word {
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
}

.Cards_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CardWrapper.is-cardBack .CardBack {
  display: flex;
}

.CardWrapper.is-cardBack.is-loading .CardBack-inner {
  opacity: 0;
}

.CW {
  margin: 0 auto;

  max-width: 350px;
  width: 100%;
  min-width: 280px;
  position: relative;
}

.Card {
  transition: transform, opacity 0.2s ease-in-out;
  /* opacity: 0; */
}

.is-layer2 {
  opacity: 1;
  margin-top: -5px;
  transform: scale(0.95) !important;
  pointer-events: none;
}

.is-layer3 {
  transform: scale(0.9) !important;
  opacity: 0.9;
  margin-top: -10px;
  pointer-events: none;
}

.is-layer4 {
  transform: scale(0.85) !important;
  opacity: 0.8;
  margin-top: -15px;
  pointer-events: none;
}
