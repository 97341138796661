.NumberInput {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 80px;
  color: var(--white);
  text-decoration: none;
  border: 0 none;
  align-items: center;
  justify-content: center;
}

.NumberInput-input {
  min-width: 200px;
  max-width: 70vw;
}

.NumberInput-plus div,
.NumberInput-minus div {
  font-size: 36px;
}

.NumberInput-plus {
  order: 2;
}

.NumberInput input[type="text"] {
  color: var(--white);
  background: transparent;
  border: none;
  font-weight: 800;
  line-height: 40px;
  font-size: 36px;
  text-align: center;
  position: relative;
  margin: auto;
  width: 100%;
  text-transform: uppercase;
}

@media (hover: hover) and (pointer: fine) {
  .Button:hover {
    opacity: 0.7;
    outline: none;
  }
}

.Button:focus {
  opacity: 0.7;
  outline: none;
}

.Button-subTitle {
  position: absolute;
  top: 40px;
  opacity: 0.6;
  font-weight: 500;
  line-height: 15px;
  font-size: 11px;
  width: 100%;
  text-transform: none;
}

.Button--colorBlue {
  background: var(--blue);
}

.Button--colorGreen {
  background: var(--green);
}

.Button--colorMagenta {
  background: var(--magenta);
}

.Button--colorRed {
  background: var(--red);
}

.Button--colorBlack {
  background: var(--black);
}

.Button--small .Button-title {
  font-size: 24px;
}