.StatisticsLayout{
  background: var(--magenta);
  color: var(--white);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 70px;
}

.StatisticsLayout-pane{
  display: flex;
  flex-grow: 5;
  flex-direction: column;
  justify-content: flex-start;
}

.StatisticsLayout-clearButton{
  margin: 0 auto;
}

.StatisticsLayout-Fallback{
  padding: 20px;
  font-size: 14px;
}

.StatisticsLayout-Fallback h2{
  font-size: 24px;
  font-weight: 600;
}

.StatisticsLayout-StatsTableFallback{
  padding: 20px;
  opacity: 0.7;
}
