.MenuLayout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.MenuLayout-appName {
  height: 15vh;
  background: var(--magenta);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.MenuLayout-menuNav {
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0;
  align-items: center;
}

.menuNav_item {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--white);
  text-decoration: none;
}

@media (hover: hover) and (pointer: fine) {
  .menuNav_item:hover {
    opacity: 0.7;
    outline: none;
  }
}

.menuNav_item:focus {
  opacity: 0.7;
  outline: none;
}

.menuNav_item_title {
  font-weight: 800;
  line-height: 40px;
  font-size: 36px;
  text-align: center;
  position: relative;
  margin: auto 10vw;
  text-transform: uppercase;
}

.menuNav_item_subTitle {
  position: absolute;
  top: 40px;
  opacity: 0.6;
  font-weight: 500;
  line-height: 15px;
  font-size: 11px;
  width: 100%;
  text-transform: none;
}