.StatsTable {
  box-sizing: border-box;
  padding: 40px 20px 20px;
}

.StatsTable tr {
  height: 25px;
}

.StatsTable th {
  font-size: 11px;
  opacity: 0.7;
}

.StatsTable td {
  font-size: 14px;
  letter-spacing: -0.01em;
}

.StatsTable th,
.StatsTable td {
  font-weight: 400;
  text-align: left;
}

.StatsTable-Header th .canRotate {
  transition: transform 0.2s ease;
}

@media screen and (max-width: 350px) {
  .canRotateContainer {
    position: relative;
  }

  .StatsTable-Header .number {
    min-width: 20px;
  }

  .StatsTable-Header .canRotate {
    transform-origin: left center;
    transform: translate(50%, 20%) rotate(-90deg);
    width: 10px;
    text-align: left;
    display: inline-block;
  }
}

.StatsTable table {
  width: 100%;
  padding-top: 10px;
}

.StatsTable-Line .number,
.StatsTable-Header .number {
  text-align: right;
}

.StatsTable.StatsTable--faded .StatsTable-Line:nth-last-child(1) {
  opacity: 0.25;
}

.StatsTable.StatsTable--faded .StatsTable-Line:nth-last-child(2) {
  opacity: 0.5;
}

.StatsTable.StatsTable--faded .StatsTable-Line:nth-last-child(3) {
  opacity: 0.75;
}

.StatsTable.StatsTable--faded .StatsTable-Line:nth-child(1) {
  opacity: 1;
}

.StatsTable-bestScore {
  color: var(--gold);
  margin-left: 3px;
}
