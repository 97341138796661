.Button {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 80px;
  color: var(--white);
  text-decoration: none;
  border: 0 none;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}

.Button:focus {
  outline: none;
}

@media (hover: hover) and (pointer: fine) {
  .Button:hover {
    opacity: 0.7;
  }
}

.Button:active .Button-title {
  transform: scale(1.1);
}

.Button-title {
  font-weight: 800;
  line-height: 40px;
  font-size: 36px;
  text-align: center;
  position: relative;
  margin: auto 10vw;
  text-transform: uppercase;
  width: 100%;

  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.Button-subTitle {
  position: absolute;
  top: 40px;
  opacity: 0.6;
  font-weight: 500;
  line-height: 15px;
  font-size: 11px;
  width: 100%;
  text-transform: none;
}

.Button--colorBlue {
  background: var(--blue);
}

.Button--colorGreen {
  background: var(--green);
}

.Button--colorMagenta {
  background: var(--magenta);
}

.Button--colorRed {
  background: var(--red);
}

.Button--colorBlack {
  background: var(--black);
}

.Button--small .Button-title {
  font-size: 24px;
}