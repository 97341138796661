.Radio ::selection {
  background: transparent;
}

.Radio {
  max-width: 450px;
  width: 100%;
  min-width: 280px;
  margin: 0 auto;
}

.Radio input[type="radio"] {
  display: none;
}

.Radio-option {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 80px;
  color: var(--white);
  text-decoration: none;
  border: 0 none;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

@media (hover: hover) and (pointer: fine) {
  .Radio-option:hover {
    opacity: 0.7;
    outline: none;
  }
}

.Radio-option:focus {
  outline: none;
}

.Radio-option:active .Radio-option-title {
  transform: scale(1.1);
}

.Radio-option-title {
  font-weight: 800;
  line-height: 40px;
  font-size: 36px;
  text-align: center;
  position: relative;
  margin: auto 2vw;
  text-transform: uppercase;

  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.Radio-option--colorBlue {
  background: var(--blue);
}

.Radio-option--colorGreen {
  background: var(--green);
}

.Radio-option--colorMagenta {
  background: var(--magenta);
}

.Radio-option--colorRed {
  background: var(--red);
}

.Radio-option--colorBlack {
  background: var(--black);
}

.Radio-option.checked {
  background: #fff;
}


.checked.Radio-option--colorBlue {
  color: var(--blue);
}

.checked.Radio-option--colorGreen {
  color: var(--green);
}

.checked.Radio-option--colorMagenta {
  color: var(--magenta);
}

.checked.Radio-option--colorRed {
  color: var(--red);
}

.checked.Radio-option--colorBlack {
  color: var(--black);
}

.Radio-option--small .Radio-option-title {
  font-size: 24px;
}