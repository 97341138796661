.ProgressBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: var(--red);
}

.ProgressBar-fill {
  height: 100%;
  background: var(--blue);
  transition: width linear 1s;
}
